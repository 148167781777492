<template>
  <div class="about" style="text-align: left">
    <v-container>
      <h1 style="text-align: left;margin-bottom: 20px">{{$t('about')}}</h1>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <h1 class="title"><p>{{ $t('historyOfInstitute') }}</p></h1>
          <p class="sub-1">{{ $t('historyL1') }}</p>
          <p class="sub-1">{{ $t('historyL2') }}</p>
          <p class="sub-1">{{ $t('historyL3') }}</p>
          <p class="sub-1">{{ $t('historyL4') }}</p>
          <p class="sub-1">{{ $t('historyL5') }}</p>

          <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('aboutInstitute') }}</p></h1>
          <p style="text-align: justify" class="sub-1">{{ $t('aboutDetail') }}</p>

          <div v-if="$vuetify.breakpoint.mobile">
            <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('vision') }}</p></h1>
            <p style="text-align: justify">{{ $t('visionDetail') }}</p>

            <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('mission') }}</p></h1>
            <v-list subheader three-line style="background-color: transparent">
              <v-list-item
                  v-for="(mi,i) in missionList"
                  :key="i"
              >
                <v-list-item-icon>
                  <v-icon :color="'green'">
                    mdi-star
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content style="text-align: justify;padding-top: 0px !important;">
                  <v-list-item-subtitle style="line-height: 2.0 !important;">{{ $t(mi.title) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

          <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('goal') }}</p></h1>
          <p style="text-align: justify">{{ $t('goalDetail') }}</p>

          </div>


        </v-col>
      </v-row>
      <div class="goal pc-tab-brand" v-if="!$vuetify.breakpoint.mobile">
        <div class="l-mess mess">
          <p class="label">{{ $t('goal') }}</p>
        </div>
        <div class="r-mess mess">
          <p class="label" style="text-align: justify">{{ $t('goalDetail') }}</p>
        </div>
        <img class="text-bg" style="width: 350px" src="@/assets/photo/goal.png" alt="goal">
        <div class="line"></div>
      </div>

      <div class="mission pc-tab-brand" id="about-con-2" v-if="!$vuetify.breakpoint.mobile">
        <div class="l-mess mess">
          <p class="label">{{$t("vision_mission")}}</p>
        </div>
        <div class="r-mess mess">
          <p class="label" style="text-align: justify">{{ $t('visionDetail') }}</p>
          <p v-for="(mi,i) in missionList" class="desc"
             :key="i">
            - {{ $t(mi.title) }}
          </p>
        </div>
        <img class="text-bg" style="width: 450px" src="@/assets/photo/Vision&Mission.png" alt="goal">

        <div class="line"></div>
      </div>

      <h1 class="title" style="padding-top: 15px;font-family: Roboto"><p>{{ $t('mapOfInstitute') }}</p></h1>

    </v-container>
    <div class="mapouter">
      <div class="gmap_canvas">
        <iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"
                src="https://maps.google.com/maps?width=900&amp;height=700&amp;hl=en&amp;q=វិទ្យាស្ថានពហុបច្ចេកទេសភូមិភាគតេជោសែនបាត់ដំបង &amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        <a href="https://www.fridaynightfunkin.net/friday-night-funkin-mods-fnf-play-online/">FNF Online</a></div>

    </div>
  </div>
</template>

<script>
import {Constants} from "@/libs/constant";

export default {
  data() {
    return {
      missionList: Constants.missionList
    }
  }
}
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.mission {
  background: #FBFBFB;
  height: 600px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}



.mission .line {
  width: 1px;
  height: 189px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.mission .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

body {
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.mission .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.mission .l-mess {
  float: left;
}

.mission .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.mission .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.mission .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.mission .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}



/*Goal*/
.goal {
  background: #FBFBFB;
  height: 300px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}



.goal .line {
  width: 1px;
  height: 160px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.goal .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

.goal .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.goal .l-mess {
  float: left;
}

.goal .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.goal .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.goal .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.goal .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}
</style>
