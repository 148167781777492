<template>
  <div class="about" style="text-align: left">
    <v-container>
      <center style="padding-top: 100px">
        <span style="font-family:'Khmer OS Muol Light';font-size: 18px">
          ក្រសួងការងារ និងបណ្តុះបណ្តាលវិជ្ជាជីវៈ<br>
          អគ្គនាយកដ្ឋានអប់រំបណ្តុះបណ្តាលបច្ចេកទេស និងវិជ្ជាជីវៈ<br>
        </span>
        <span style="font-family: 'Khmer OS Muol Light';font-size: 16px">
          {{ certificatesKh }}<br>
          {{ certificatesEn }}<br>
        </span>
        <v-img :src="studentInfo.photo" style="width: 4cm;height: 6cm"></v-img>
        <br>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span style="font-family: 'Khmer OS Battambang';font-size: 18px">ព័ត៌មានទូទៅ</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            ឈ្មោះ៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.name }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            Full Name៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.enName }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            ភេទ៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.gender }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            Gender៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.enGender }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            ថ្ងៃខែឆ្នាំកំណើត៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.dob }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            Date of Birth៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.enDob }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            ជំនាញ៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.major }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            Specialization៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.enMajor }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            ផ្តល់ជូននៅថ្ងៃទី៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.issueDate }}
          </v-col>
          <v-col cols="5" sm="5" md="5" class="studentInfoLabel">
            Issue Date៖
          </v-col>
          <v-col cols="7" sm="7" md="7" class="studentInfo">
            {{ studentInfo.enIssueDate }}
          </v-col>
        </v-row>

        <table class="timecard" style="margin-top: 30px;overflow-x: auto;width: 100%">
          <caption>សមត្ថភាពស្នូល <br>Core Competency</caption>
          <thead>
          <tr>
            <th>លេខកូដ</th>
            <th>ចំណងជើង</th>
            <th>Title</th>
            <th>ចំនួនម៉ោង(Hours)</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="ind%2===1 ? 'even' : 'odd'" v-for="(doc,ind) in competencyList" :key="ind">
            <td style="text-align: center">{{ doc.code }}</td>
            <td style="text-align: left">{{ doc.title }}</td>
            <td style="text-align: left">{{ doc.titleEn }}</td>
            <td style="text-align: center">{{ doc.hour }}</td>
          </tr>

          </tbody>
          <tfoot>
          <tr>
            <td colspan="3">សរុប</td>
            <td>៦៣០</td>
          </tr>
          </tfoot>
        </table>
      </center>

    </v-container>
  </div>
</template>

<script>
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  data() {
    return {
      certificatesEn: "Technical and Vocational Certificate 1",
      certificatesKh: "សញ្ញាបត្របច្ចេកទេស និងវិជ្ជាជីវៈ១",
      competencyList: [
        {
          code: "1",
          title: "ដំឡើងបណ្តាញអគ្គិសនីសម្រាប់ប្រព័ន្ធបំភ្លឺ",
          titleEn: "Install electrical wiring for lighting system",
          hour: "២៤០"
        },
        {
          code: "2",
          title: "ដំឡើងបណ្តាញអគ្គិសនីសម្រាប់ឆ្នាប់ចរន្តមួយផាស និងឆ្នាប់ចរន្តបីផាស",
          titleEn: "Install electrical wiring for convenience and power outlets",
          hour: "៧៥"
        },
        {
          code: "3",
          title: "ដំឡើងប្រព័ន្ធសុវត្ថិភាពក្នុងគេហដ្ឋាន និងប្រព័ន្ធសម្រាប់ការប្រាស្រ័យទាក់ទង",
          titleEn: "Install domestic safety/security and communication systems",
          hour: "៩០"
        },
        {
          code: "4",
          title: "ដំឡើងបណ្តាញបន្តចរន្តមេចូលគេហដ្ឋាន និងទូរចែកចាយ",
          titleEn: "Install domestic service entrance and service equipment ",
          hour: "១៥០"
        },
        {
          code: "5",
          title: "ប្រតិបត្តិការជួសជុលតូចតាច",
          titleEn: "Perform punch listing activities",
          hour: "៧៥"
        },
      ],
      studentInfo: {
        registerId: "",
        name: "មៀន ជំនាញ",
        enName: "Mean Chumneanh",
        gender: "ស្រី",
        enGender: "Female",
        dob: "១២ កញ្ញា ២០០០",
        enDob: "12 September 2000",
        major: "ការដំឡើងបណ្តាញអគ្គិសនីក្នុងអគារ",
        enMajor: "Domestic Refrigerate Air-Condition Servicing",
        issueDate: "១២ កញ្ញា ២០២៣",
        enIssueDate: "12 September 2023",
        photo: "https://gic.itc.edu.kh/storage/staffs/August2019/jEH7dgj2iVV8hCWL6j2o.jpg"

      }
    }
  },
  methods: {
    getStudentByRegisterId(id) {
      let vm = this;
      let url = Constants.baseURLStudent + Constants.api.studentByRegisterId;
      return new Promise((resolve, reject) => {
        axios.post(url, {id: id}, {headers: {token: "narongpos"}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                console.log(res.data.data.studentDoc);
                vm.studentInfo.name = res.data.data.studentDoc.personal.name;
                vm.studentInfo.enName = res.data.data.studentDoc.personal.latinName;
                vm.studentInfo.gender = res.data.data.studentDoc.personal.gender === "Female" ? "ស្រី" : "ប្រុស";
                vm.studentInfo.enGender = res.data.data.studentDoc.personal.gender;
                vm.studentInfo.major = res.data.data.majorDoc.name;
                //vm.studentInfo.enMajor = res.data.data.majorDoc.name;
                vm.studentInfo.photo = res.data.data.studentDoc.imgUrl;
                /*vm.studentInfo.dob = res.data.data.studentDoc.dobName;
                vm.studentInfo.enDob = res.data.data.studentDoc.dobName;*/
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    this.studentInfo.registerId = this.$route.params.id;
    this.getStudentByRegisterId(this.studentInfo.registerId);
  }
}
</script>

<style scoped>
.studentInfo {
  font-size: 16px;
  font-family: "Khmer OS Battambang";
  text-align: left;
}

.studentInfoLabel {
  font-size: 16px;
  font-family: "Khmer OS Battambang";
  text-align: left;
  padding-left: 40px;
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 700px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 700px;
}

.gmap_iframe {
  height: 700px !important;
}

.mission {
  background: #FBFBFB;
  height: 600px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.mission .line {
  width: 1px;
  height: 189px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.mission .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

body {
  color: #fff;
  line-height: 1.5;
  font-size: 14px;
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.mission .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.mission .l-mess {
  float: left;
}

.mission .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.mission .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.mission .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.mission .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}


/*Goal*/
.goal {
  background: #FBFBFB;
  height: 300px;
  width: 100%;
  position: relative;
  margin-top: 96px;
  padding: 60px 96px;
  box-sizing: border-box;
  margin-bottom: 50px;
}


.goal .line {
  width: 1px;
  height: 160px;
  background: #D8D9DC;
  position: absolute;
  left: 55.24%;
  top: 67px;
  display: none;
}

.goal .r-mess {
  float: right;
  width: 405px;
  height: 190px;
  position: relative;
}

.goal .l-mess .label {
  font-size: 30px;
  color: #2A2E2E;
  letter-spacing: 0;
  font-family: 'TencentSansw7';
}

.goal .l-mess {
  float: left;
}

.goal .r-mess .label {
  font-size: 20px;
  color: #2A2E2E;
  letter-spacing: 0;
  line-height: 36px;
}

.goal .r-mess .desc {
  font-size: 14px;
  color: #5F6464;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: 16px;
}

.goal .r-mess:before {
  content: ' ';
  width: 1px;
  position: absolute;
  left: -60px;
  top: 0;
  display: block;
  bottom: 0;
  background: #D8D9DC;
}

.goal .text-bg {
  width: 824px;
  position: absolute;
  left: 82px;
  bottom: 0;
}

#main {
  width: 780px;
  padding: 20px;
  margin: auto;
}

table.timecard {
  margin: auto;
  width: 600px;
  border-collapse: collapse;
  border: 1px solid #fff; /*for older IE*/
  border-style: hidden;
}

table.timecard caption {
  background-color: #f79646;
  color: #fff;
  font-size: x-large;
  font-weight: bold;
  letter-spacing: .3em;
}

table.timecard thead th {
  padding: 8px;
  background-color: #fde9d9;
  font-size: large;
}

table.timecard thead th#thDay {
  width: 40%;
}

table.timecard thead th#thRegular, table.timecard thead th#thOvertime, table.timecard thead th#thTotal {
  width: 20%;
}

table.timecard th, table.timecard td {
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #f79646 #ccc;
}

table.timecard td {
  text-align: right;
}

table.timecard tbody th {
  text-align: left;
  font-weight: normal;
}

table.timecard tfoot {
  font-weight: bold;
  font-size: large;
  background-color: #687886;
  color: #fff;
}

table.timecard tr.even {
  background-color: #fde9d9;
}
</style>
